require("core-js/modules/es.object.to-string");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.App = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //
  //
  //
  //
  //
  //
  var _default = {
    name: 'App',
    data: function data() {
      return {};
    },
    created: function created() {
      window.alert = function (msg) {
        console.log(msg);
      };

      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      var _vm$$config$base = vm.$config.base,
          ADMIN_URL = _vm$$config$base.ADMIN_URL,
          ADMIN_TOKEN = _vm$$config$base.ADMIN_TOKEN;

      if (name) {
        vm.$http({
          url: "".concat(ADMIN_URL, "javaApi/iot/device/types"),
          headers: {
            'access-token': ADMIN_TOKEN
          },
          except: true
        }).then(function (res) {
          window.deviceTypes = res.data;
        });
      }
    },
    methods: {}
  };
  _exports.default = _default;
});