require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.object.to-string");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/dialog/style", "vant/es/dialog", "axios", "@/config/index"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/dialog/style"), require("vant/es/dialog"), require("axios"), require("@/config/index"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.dialog, global.axios, global.index);
    global.ajax = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _dialog, _axios, _index) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _dialog = _interopRequireDefault(_dialog);
  _axios = _interopRequireDefault(_axios);

  // create an axios instance
  var service = _axios.default.create({
    baseURL: _index.base.BASE_URL,
    // request timeout
    timeout: 30000,
    // 请求失败重试
    retry: _index.base.RETRY_COUNT,
    // 重试间隔时间
    retryDelay: _index.base.RETRY_DELAY
  }); // request interceptor


  service.interceptors.request.use(function (config) {
    // if (store.getters.token) {
    //     config.headers['access-token'] = getToken();
    // }
    config.type && (config.method = config.type);
    return config;
  }, function (error) {
    console.log(error);
    return Promise.reject(error);
  }); // response interceptor

  service.interceptors.response.use(function (response) {
    var res = response.data;

    if (res.code !== 1 && !response.config.except) {
      _dialog.default.alert({
        title: '提示',
        message: res.message ? "".concat(res.message.sqlMessage || res.message, "--").concat(response.config.url) : '服务器繁忙！请稍后再试~'
      });

      return Promise.reject(res.message || 'Error');
    } else {
      return res;
    }
  }, function (error) {
    // 请求超时时隔10秒再次重试1次
    if (error.message.indexOf('timeout') > -1) {
      var config = error.config;

      if (!config || !config.retry) {
        return Promise.reject(error);
      }

      config.__retryCount = config.__retryCount || 0;

      if (config.__retryCount >= config.retry) {
        _dialog.default.alert({
          message: error.message,
          title: 'timeout'
        });

        return Promise.reject(error);
      }

      config.__retryCount += 1;
      var backoff = new Promise(function (resolve) {
        setTimeout(function () {
          resolve();
        }, config.retryDelay || 1);
      });
      return backoff.then(function () {
        return service(config);
      });
    } else {
      console.log('err' + error); // for debug

      _dialog.default.alert({
        message: error.message || '网络错误',
        title: '提示'
      });
    }
  });
  var _default = service;
  _exports.default = _default;
});