require("core-js/modules/es.object.to-string");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.factoryRouter = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var factory = {
    path: '/factory',
    redirect: '/factory/index',
    name: 'Factory',
    component: function component() {
      return import('@/Layout');
    },
    meta: {
      title: '质量检测'
    },
    children: [{
      path: 'index',
      component: function component() {
        return import('@/pages/factory/index');
      },
      name: 'FactoryIndex',
      meta: {
        title: '质量检测'
      }
    }, {
      path: 'login',
      component: function component() {
        return import('@/pages/factory/login');
      },
      name: 'FactoryLogin',
      meta: {
        title: '登录'
      }
    }, {
      path: 'checkInfo',
      component: function component() {
        return import('@/pages/factory/checkInfo');
      },
      name: 'CheckInfo',
      meta: {
        title: '质检'
      }
    }, {
      path: 'description',
      component: function component() {
        return import('@/pages/factory/description');
      },
      name: 'FactoryDescription',
      meta: {
        title: '使用说明'
      }
    }, {
      path: 'quality/qualityList',
      component: function component() {
        return import('@/pages/factory/quality/qualityList');
      },
      name: 'RyQualityList',
      meta: {
        title: '返厂定损'
      }
    }, {
      path: 'quality/createOrder',
      component: function component() {
        return import('@/pages/factory/quality/createOrder');
      },
      name: 'DeviceCreateOrder',
      meta: {
        title: '新建返厂单'
      }
    }, {
      path: 'quality/history/:id',
      component: function component() {
        return import('@/pages/factory/quality/qualityHistory');
      },
      name: 'RyQualityHistory',
      meta: {
        title: '工单详情'
      }
    }, {
      path: 'quality/info/:id',
      component: function component() {
        return import('@/pages/factory/quality/qualityOrderInfo');
      },
      name: 'FactoryQualityOrderInfo',
      meta: {
        title: '质检单'
      }
    }, {
      path: 'afterSale/uploadImgPage',
      component: function component() {
        return import('@/pages/factory/afterSale/uploadImgPage');
      },
      name: 'RyUploadImgPage',
      meta: {
        title: '异常探头处理'
      }
    }, {
      path: 'afterSale/addUploadImgPage',
      component: function component() {
        return import('@/pages/factory/afterSale/addUploadImgPage');
      },
      name: 'RyAddUploadImgPage',
      meta: {
        title: '新建记录'
      }
    }, {
      path: 'afterSale/acceptanceCheckWarningList',
      component: function component() {
        return import('@/pages/factory/afterSale/acceptanceCheckWarningList');
      },
      name: 'AcceptanceCheckWarningList',
      meta: {
        title: '验收记录'
      }
    }, {
      path: 'afterSale/determineRes',
      component: function component() {
        return import('@/pages/factory/afterSale/determineRes');
      },
      name: 'DetermineRes',
      meta: {
        title: '复核订单'
      }
    }, {
      path: 'stock/checkDevice',
      component: function component() {
        return import('@/pages/factory/stock/checkDevice');
      },
      name: 'CheckDevice',
      meta: {
        title: '校验'
      }
    }, {
      path: 'quality/tlQaInfo/:id',
      component: function component() {
        return import('@/pages/factory/quality/tlQaInfo');
      },
      name: 'TlQaInfo',
      meta: {
        title: '质检详情'
      }
    }, {
      path: 'quality/tlQa/:id',
      component: function component() {
        return import('@/pages/factory/quality/tlQa');
      },
      name: 'TlQa',
      meta: {
        title: '控制盒质检'
      }
    }]
  };
  var _default = factory;
  _exports.default = _default;
});