require("core-js/modules/es.object.to-string");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.ryProbeRouter = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ryProbe = {
    path: '/ryProbe',
    redirect: '/ryProbe/list',
    name: 'RyProbe',
    component: function component() {
      return import('@/Layout');
    },
    meta: {
      title: '质量检测'
    },
    children: [{
      path: 'list',
      component: function component() {
        return import('@/pages/ryProbe/list');
      },
      name: 'RyProbeList',
      meta: {
        title: '质量检测'
      }
    }, {
      path: 'info',
      component: function component() {
        return import('@/pages/ryProbe/info');
      },
      name: 'RyProbeInfo',
      meta: {
        title: '质量检测'
      }
    }, {
      path: 'afterSale/login',
      component: function component() {
        return import('@/pages/ryProbe/afterSale/login');
      },
      name: 'Login',
      meta: {
        title: '质量检测'
      }
    }, {
      path: 'afterSale/list',
      component: function component() {
        return import('@/pages/ryProbe/afterSale/list');
      },
      name: 'RyProbeAfterSaleList',
      meta: {
        title: '返厂维护'
      }
    }, {
      path: 'afterSale/info/:id',
      component: function component() {
        return import('@/pages/ryProbe/afterSale/info');
      },
      name: 'RyProbeAfterSaleInfo',
      meta: {
        title: '返厂维护详情'
      }
    }, {
      path: 'afterSale/warningList',
      component: function component() {
        return import('@/pages/ryProbe/afterSale/warningProbeList');
      },
      name: 'RyWarningProbeList',
      meta: {
        title: '异常探头记录'
      }
    }, {
      path: 'preSale/list',
      component: function component() {
        return import('@/pages/ryProbe/preSale/checkTest');
      },
      name: 'RyPreSaleCheckTestList',
      meta: {
        title: '出厂校验'
      }
    }]
  };
  var _default = ryProbe;
  _exports.default = _default;
});