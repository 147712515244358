(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.localStorage = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getItem = getItem;
  _exports.setItem = setItem;
  _exports.removeItem = removeItem;

  function getItem(key) {
    if (!key) {
      console.log('!!!err:$FN_getItem 参数为必传');
      return '';
    }

    var val = window.localStorage.getItem(key) || '';

    try {
      val = JSON.parse(val);
    } catch (error) {
      console.log("getIem::".concat(key, "_\u8BE5\u503C\u4E3A\u975E\u5BF9\u8C61\u6570\u7EC4"));
    }

    return val;
  }

  function setItem(key, val) {
    if (!key) {
      console.log('!!!err:$FN_setItem 参数为必传');
      return '';
    }

    var value = val;

    if (typeof value != 'string') {
      try {
        value = JSON.stringify(value);
      } catch (error) {
        console.log("setItem::".concat(key, "_\u8BE5\u503C\u4E3A\u975E\u5BF9\u8C61\u6570\u7EC4"));
      }
    }

    window.localStorage.setItem(key, value);
  }

  function removeItem(key) {
    if (!key) {
      console.log('!!!err:$FN_removeItem 参数为必传');
      return '';
    }

    window.localStorage.removeItem(key);
  }
});