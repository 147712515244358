(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["/root/deploy/iot-product-tool/node_modules/core-js/modules/es.array.iterator.js", "/root/deploy/iot-product-tool/node_modules/core-js/modules/es.promise.js", "/root/deploy/iot-product-tool/node_modules/core-js/modules/es.object.assign.js", "/root/deploy/iot-product-tool/node_modules/core-js/modules/es.promise.finally.js", "vue", "./App.vue", "@/config", "element-ui", "element-ui/lib/theme-chalk/index.css", "echarts", "./router", "vue-router", "@/utils/localStorage", "@/utils/ajax", "@/utils/time", "@/utils/str"], factory);
  } else if (typeof exports !== "undefined") {
    factory(require("/root/deploy/iot-product-tool/node_modules/core-js/modules/es.array.iterator.js"), require("/root/deploy/iot-product-tool/node_modules/core-js/modules/es.promise.js"), require("/root/deploy/iot-product-tool/node_modules/core-js/modules/es.object.assign.js"), require("/root/deploy/iot-product-tool/node_modules/core-js/modules/es.promise.finally.js"), require("vue"), require("./App.vue"), require("@/config"), require("element-ui"), require("element-ui/lib/theme-chalk/index.css"), require("echarts"), require("./router"), require("vue-router"), require("@/utils/localStorage"), require("@/utils/ajax"), require("@/utils/time"), require("@/utils/str"));
  } else {
    var mod = {
      exports: {}
    };
    factory(global.esArrayIterator, global.esPromise, global.esObjectAssign, global.esPromiseFinally, global.vue, global.App, global.config, global.elementUi, global.index, global.echarts, global.router, global.vueRouter, global.localStorage, global.ajax, global.time, global.str);
    global.main = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_esArrayIterator, _esPromise, _esObjectAssign, _esPromiseFinally, _vue, _App, config, _elementUi, _index, echarts, _router, _vueRouter, $localStorage, _ajax, Time, StrTool) {
  "use strict";

  var _interopRequireWildcard = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireWildcard");

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  _vue = _interopRequireDefault(_vue);
  _App = _interopRequireDefault(_App);
  config = _interopRequireWildcard(config);
  _elementUi = _interopRequireDefault(_elementUi);
  echarts = _interopRequireWildcard(echarts);
  _router = _interopRequireDefault(_router);
  _vueRouter = _interopRequireDefault(_vueRouter);
  $localStorage = _interopRequireWildcard($localStorage);
  _ajax = _interopRequireDefault(_ajax);
  Time = _interopRequireWildcard(Time);
  StrTool = _interopRequireWildcard(StrTool);
  _vue.default.prototype.$config = config;
  _vue.default.prototype.$localStorage = $localStorage;
  _vue.default.prototype.$http = _ajax.default;
  _vue.default.prototype.$time = Time;
  _vue.default.prototype.$strTool = StrTool;
  _vue.default.prototype.$echarts = echarts;

  _vue.default.use(_vueRouter.default);

  _vue.default.use(_elementUi.default);

  _vue.default.config.productionTip = false;

  var _routes = new _vueRouter.default({
    routes: _router.default // mode: 'history'

  });

  _routes.beforeEach(function (to, from, next) {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
      document.title = to.meta.title;
    }

    next();
  });

  new _vue.default({
    router: _routes,
    el: '#app',
    render: function render(h) {
      return h(_App.default);
    }
  });
});